html, body {
  height: 100%;
}

body {
  background-color: #EEEEEE;
  font-family: 'Open Sans', sans-serif;
  color: #444546;
  font-size: 14px;
}

#footer {
  height: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

#footer a {
  color: #999999;
}

.logo {
  margin-bottom: 15px;
}

.logo h1 {
  margin-top: 50px;
  font-size: 64px;
}

.logo span {
  color: #3cb8f1;
}

.logo small {
  color: #696758;
}

p {
  font-size:14px;
}

a, a:visited, a:hover, a:active {
  color: #000;
}

.h1 a, .h1 a:visited, .h1 a:hover, .h1 a:active {
  color: inherit;
  text-decoration: none;
}
